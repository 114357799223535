
























import Button from '@/components/button.vue';
import OfferHeader from '@/components/offer-header.vue';
import OfferOrdered from '@/components/offer-ordered.vue';
import ReadMore from '@/components/read-more.vue';
import {Component} from '@/decorators';
import {PortalSettings, PublicSubscriptionPortalOffer} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';

@Component({
    components: {Button, OfferOrdered, ReadMore, OfferHeader}
})
export default class UpgradeView extends Vue {
    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private settings: PortalSettings;

    @Action
    private upgradeSubscription: () => Promise<void>;

    public created() {
        if (!this.offer.upgrade) {
            this.next();
        }
    }

    private upgrade() {
        this.upgradeSubscription().then(this.next);
    }

    private next() {
        this.$router.push({
            name: 'iban-view',
            params: this.$route.params
        });
    }

    private get image(): string {
        if (this.settings.useV1Images) {
            return this.offer.upgrade.image;
        } else {
            return this.offer.upgrade.imageV1;
        }
    }

    private get oldPortalUrl(): string {
        return `/portal#/${this.$route.params.visitId}/subscription/upgrade/${this.$route.params.offerId}`;
    }
}
