var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offer.upgrade
    ? _c(
        "div",
        { staticClass: "upgrade-view container mt-4" },
        [
          _c("offer-ordered", {
            attrs: {
              "bullet-points": _vm.offer.form.details.bulletPoints,
              description: _vm.offer.thankYou.description,
              image: _vm.offer.form.productImage,
              title: _vm.offer.thankYou.headline
            }
          }),
          _vm.offer.upgrade.headline
            ? _c("h2", {
                staticClass: "upgrade-title",
                domProps: { innerHTML: _vm._s(_vm.offer.upgrade.headline) }
              })
            : _vm._e(),
          _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-6" }, [_vm._m(0)]),
            _c(
              "div",
              { staticClass: "col-md-6 upgrade-form" },
              [
                _vm.offer.upgrade.subHeadline
                  ? _c("h3", {
                      staticClass: "upgrade-subtitle",
                      domProps: {
                        innerHTML: _vm._s(_vm.offer.upgrade.subHeadline)
                      }
                    })
                  : _vm._e(),
                _vm.offer.upgrade.description
                  ? _c(
                      "div",
                      { staticClass: "upgrade-body" },
                      [
                        _c("ReadMore", {
                          attrs: {
                            value: _vm.offer.upgrade.description,
                            cutFrom: 30
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._m(1),
                _vm._m(2),
                _vm._m(3)
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("img", {
      staticClass: "upgrade-image img-fluid",
      attrs: { src: _vm.image, alt: "Upgrade Image" }
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("Button", {
      staticClass: "upgrade-button mb-2 mt-2",
      attrs: {
        data: _vm.offer.upgrade.upgradeButton,
        fill: "",
        type: "custom"
      },
      on: { click: _vm.upgrade }
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "cancel-description" }, [
      _vm._v(_vm._s(_vm.offer.upgrade.cancelDescription))
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("Button", {
      staticClass: "cancel-button",
      attrs: { data: _vm.offer.upgrade.cancelButton, fill: "", type: "custom" },
      on: { click: _vm.next }
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }