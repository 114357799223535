







import Button from '@/components/button.vue';
import {Component, Prop} from '@/decorators';
import {PortalSettings} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Getter} from 'vuex-class';

@Component({
    components: {Button}
})
export default class ReadMore extends Vue {
    @Prop
    private value: string;

    @Prop
    private cutFrom: number;

    @Getter
    private settings: PortalSettings;

    private collapsed: boolean = false;

    public beforeMount(): void {
        this.collapsed = this.cutFrom > 0;
    }

    private get displayValue(): string {
        if (!this.collapsed || this.cutFrom < 1) {
            return this.value;
        }

        let displayValue = '';
        let closeTags: string[] = [];
        let words = 0;
        let i = 0;

        while (words < this.cutFrom) {
            if (i >= this.value.length) {
                this.collapsed = false;
                return this.value;
            }
            if (this.value.charAt(i) === '<') {
                let end = this.value.indexOf('>', i);
                let htmlTag = this.value.substring(i, end + 1);
                i = end + 1;
                if (htmlTag.charAt(1) === '/') {
                    displayValue += closeTags.pop();
                } else {
                    let tagName = /<([a-zA-Z]+)(?: .*)?>/.exec(htmlTag)![1];
                    closeTags.push(`</${tagName}>`);
                    displayValue += htmlTag;
                }
                continue;
            }

            let nextWord = this.value.indexOf(' ', i);
            let nextTag = this.value.indexOf('<', i);
            let wordEnd;
            if (nextWord !== -1) {
                if (nextTag !== -1) {
                    wordEnd = Math.min(nextWord, nextTag - 1);
                } else {
                    wordEnd = nextWord;
                }
            } else {
                wordEnd = nextTag;
            }
            if (wordEnd === -1) {
                wordEnd = this.value.length;
            } else {
                wordEnd++; // include the space
            }
            let word = this.value.substring(i, wordEnd);
            i = wordEnd;
            words++;
            displayValue += word;
        }

        if (!displayValue.endsWith(' ')) {
            displayValue += ' ';
        }

        while (closeTags.length > 0) {
            displayValue += closeTags.pop();
        }
        return displayValue;
    }
}
