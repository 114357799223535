var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "read-more" }, [
    _c("span", {
      staticClass: "content",
      domProps: { innerHTML: _vm._s(_vm.displayValue) }
    }),
    _vm.collapsed
      ? _c(
          "a",
          {
            staticClass: "read-more-button",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.collapsed = false
              }
            }
          },
          [_vm._v(_vm._s(_vm.settings.readMoreText))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }